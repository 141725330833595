
.arrayContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    .row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .actionCell{
        width: 5%;
        text-align: center;
    }
    .idCell{
        width: 10%;
        text-align: center;
    }
    .nameCell{
        width: 85%;
        text-align: center;
    }
}