@import "style/layout";

.mainBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.part{
    display: flex;
    flex-direction: column;
    width: 550px;
    padding: 0px;
}

.partLeft {
  margin: 5px 10px 0px 0px;
}

.partRight {
  margin: 5px 0 0px 10px;
}

.partPaper{
  padding: 10px;
}

.partPaperDottom{
  padding: 10px;
  margin-top: 10px;
}

.titre {
  padding-bottom: 20px;
}

.imagesPart {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.imagePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 15px;
}

.imagePickerContainer-middle{
    margin-left: 15px;
    margin-right: 15px;
}

.imageDiv {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageDiv img {
  max-height: 100px;
  max-width: 100px;
  cursor: pointer;
}

.imagePickerContainer span {
  font-size: 10px;
}

.colorPickerContainer {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.colorPickerContainer input{
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.colorPickerContainer input:focus{
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.colorPickerContainer-sample{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.corporationtoolbar{
  display: flex;
  flex-direction: row;
}