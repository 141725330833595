@import "style/layout";

.typography {
  margin-top: 50px;
  color: white;
  font-size: 40px;
}

.box {
  background-color: var(--secondary-bg-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
