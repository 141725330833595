@import "style/layout";

.userSearchMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
}

.userDialogContent {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  margin: 10px 0px 0px 15px;
}

.userListPart {
  transition: width 0.5s;
}

.userDetailPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: width 0.5s;
}

.userAddContainer {
  z-index: 1501;
  background-color: rgb(247, 247, 247);
  display: flex;
  flex-direction: column;
}

.userAddForm {
  width: 90%;
  margin: 10px 5px 10px 5px;
}

.userAddActions {
  width: 100%;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.toolbarUser {
  background-color: var(--title-bg-color);
  color: #fff;
  width: 100%;
}

.userDetailContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.userTextFieldStyle{
  width: 100%;
  margin-top: 10px;
}
.userAutoCompleteStyle{
  margin-top: 10px;
}

.userButton{
  background-color: #444;
  color: #fff;
  border-color: #444;
  margin: 5px;
  &:hover{
    background-color: #fff;
    color: #444;
    border-color: #444;
  }
}

.dialogUser {
  width: 100%;
  .MuiDialog-container {
    width: 100%;
    min-width: 100%;
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      background-color: rgb(247, 247, 247);
      .userDetailContainer {
        .MuiPaper-root {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
        }
      }
      .MuiSnackbar-root{
        display: flex;
        flex-direction: row;
        .MuiPaper-root{
          width: 100%;
          color: black;
          background-color: #fff;
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}
