@import "style/layout";

.corporation-vignette {
  background-color: #ffffff;
  border-radius: 5px;
  margin: 15px;
  width: 270px;
  height: 250px;
  margin-bottom: 30px;
}
.corporation-vignette-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--title-bg-color);
  font-family: "gotham-light";
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: 800;
  min-height: 80px;
}
.corporation-vignette-header img {
  width: 30px;
}
.corporation-vignette-header span {
  margin-left: 10px;
}
.corporation-vignette-body {
  height: 165px;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.corporation-vignette-actions {
  background-color: var(--title-bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.corporation-status {
  margin-right: 5px;
  color: #ffffff;
  font-weight: 800;
  cursor: pointer;
}
.coroporationSearchContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px 10px 50px;
}


.corporation-status-intialized {
}
.corporation-status-pending {
}
.corporation-status-deployed {
  color: green;
}
.corporation-status-error {
  color: red;
}
.MuiButtonBase-root {
  & .MuiTab-root{
    & .Mui-selected {
      background-color: var(--title-bg-color);
      color: white;
    }
    color: white;
    font-weight: 1000;
  }
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: white;
    font-weight: 1000;
}

.div_icon{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.svg_icons {
  transform: scale(2);
}