
.siteAddressContainer{
    display: flex;
    flex-direction: row;
}

.siteAddressForm{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.siteAddressGeo{
    width: 100%;
}

.siteAddressMap{
    width: 500px;
    height: 300px;
    margin-bottom: 20px;
}