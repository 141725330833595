@font-face {
    font-family: "Gotham-Light";
    src: url("fonts/Gotham-Light.otf")
}

@font-face {
    font-family: "Gotham-Black";
    src: url("fonts/Gotham-Black.otf")
}



:root{
    --main-bg-color: #fff;
    --secondary-bg-color: #444;
    --light-bg-color: rgb(116, 120, 144);
    --page-bg-color: rgb(116, 120, 144, 0.1);
    --title-bg-color: rgb(68, 68, 68, 0.5);
    --table-bg-color: rgb(161,161,161);
}

body{
    font-family: 'Gotham-Light';
}

.content{
    flex-grow: 1;
    padding: 0;
    background-color: #eeeeee;
    height: 100vh;
}

.navbar{
    background-color:var(--main-bg-color);
    color: #000;
}
.sidebar{
    width: 150px;
    flex-shrink: 0;
    background-color: var(--secondary-bg-color);
    color: 'white';
    font-family: 'Gotham-Light';
}
.sidebar .MuiDrawer-paper{
    background-color: var(--secondary-bg-color);
    width: 150px;
     box-sizing: 'border-box'
}

.navbarSpan{
    color: #000;
    font-family: 'Gotham-Black';
}
