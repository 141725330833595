@import "style/layout";

.siteSearchMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.siteDialogContent {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  margin: 10px 0px 0px 15px;
}

.siteListPart {
  transition: width 0.2s;
}

.siteDetailPart {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  align-content: start;
  transition: width 0.2s;
}

.toolbarSite {
  background-color: var(--title-bg-color);
  color: #fff;
  width: 100%;
}

.siteDetailContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.siteDetailGeneralinformationContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  width: 100%;
  align-items: start;
  align-content: start;
  .paperDiv {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
  .topLeft {
    grid-row: 1;
    grid-column: 1;
    margin-left: 10px;
    margin-right: 5px;
  }
  .topRight {
    grid-row: 1;
    grid-column: 2;
    margin-left: 5px;
    margin-right: 10px;
  }
  .bottom {
    grid-row: 2;
    grid-column: 1 / span 2;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.siteDetailActivityContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .paperDiv {
    margin-top: 20px;
    width: 60%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}

.siteDetailWasteContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .paperDiv {
    margin-top: 20px;
    width: 80%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }
}

.buttonTabContainer {
  display: flex;
  flex-direction: row;
}

.buttonTab {
  margin: 10px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 15px;
}
.buttonTabActive {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: #444;
  font-weight: 600;
}

.detailFormContainer {
  width: 100%;
  margin: 10px;
}

.titleForm{
  font-weight: 600;
  color: #fff;
  background-color: var(--title-bg-color);
  padding: 5px;
  margin-bottom: 10px;
}

.detailInput {
  margin: 5px;
  .customInputLabel {
  }
  .customInputValue {
    width: 100%;
    input {
      border: none;
      border-bottom: 1px solid #c2bfbf;
    }
  }
}

.siteButton{
  background-color: #444;
  color: #fff;
  border-color: #444;
  margin: 5px;
  &:hover{
    background-color: #fff;
    color: #444;
    border-color: #444;
  }
}
.siteButtonVisible{
  display: inline-block;
}
.siteButtonInvisible{
  display: none;
}

.siteDetailAction{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.dialogSite {
  width: 100%;
  .MuiDialog-container {
    width: 100%;
    min-width: 100%;
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      background-color: rgb(247, 247, 247);
    }
    .MuiSnackbar-root {
      display: flex;
      flex-direction: row;
      .MuiPaper-root {
        width: 100%;
        color: black;
        background-color: #fff;
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.selectWaste{
  width: 70%;
}

.siteArrayContainer{
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  align-content: start;
  justify-content: start;
  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
  .actionCell{
    width: 5%;
  }
  .idCell{
    width: 10%;
  }
}

