@import "style/layout";

.workflowDrawerContainer{
    width: 500px;
}

.workflowTollbarContainer{
    padding: 10px 0px 10px 20px;
}

.workflowListItemContainer{
    display: flex;
    flex-direction: row;
    align-items:center;
}

.workflowListItemPaper{
    margin: 10px;;
    padding: 10px 0px 10px 10px;
    display: flex;
    flex-direction: column;
}

.workflowTaskListItemContainer{
    margin: 5px 0px 5px 30px;
    display: flex;
    flex-direction: row;
}

.workflowTaskListItemTitle{
    margin-left: 10px;
}
 
.workflowTaskListItemStatus{
    margin-right: 10px;
}

.workflowListItemStatus{
    margin-right: 10px;
}