
.popperUpload{
    z-index: 3000;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 350px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;;
}