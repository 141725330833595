
.item-status {
    margin-right: 5px;
    color: #ffffff;
    font-weight: 800;
    cursor: pointer;
  }
  
.item-status-intialized {
  color: rgb(180, 187, 180);
}
.item-status-pending {
  color: rgb(180, 187, 180);
}
.item-status-deployed {
  color: rgb(77, 151, 77);
}
.item-status-error {
  color: rgb(151, 22, 22);
}
.customInputContainer{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
}
.customInputLabel{
  width: 30%;
  margin-right: 10px;
}
.customInputValue{
  width: 50%;
  input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #c2bfbf;
    &:focus{
      outline: none;
      border-bottom: 1px solid #383737;
    }
  }
}