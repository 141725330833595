@import "style/layout";

.customerSearchMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
}

.customerDialogContent {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  margin: 10px 0px 0px 15px;
}

.customerListPart {
  transition: width 0.5s;
}

.customerDetailPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: width 0.5s;
}

.customerAddContainer {
  z-index: 1501;
  background-color: rgb(247, 247, 247);
  display: flex;
  flex-direction: column;
}

.customerAddForm {
  width: 90%;
  margin: 10px 5px 10px 5px;
}

.customerAddActions {
  width: 100%;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.toolbarCustomer {
  background-color: var(--title-bg-color);
  color: #fff;
  width: 100%;
}

.customerDetailContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.customerTextFieldStyle{
  width: 100%;
  margin-top: 10px;
}
.customerAutoCompleteStyle{
  margin-top: 10px;
}

.customerButton{
  background-color: #444;
  color: #fff;
  border-color: #444;
  margin: 5px;
  &:hover{
    background-color: #fff;
    color: #444;
    border-color: #444;
  }
}

.dialogCustomer {
  width: 100%;
  .MuiDialog-container {
    width: 100%;
    min-width: 100%;
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px;
      background-color: rgb(247, 247, 247);
      .customerDetailContainer {
        .MuiPaper-root {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
        }
      }
      .MuiSnackbar-root{
        display: flex;
        flex-direction: row;
        .MuiPaper-root{
          width: 100%;
          color: black;
          background-color: #fff;
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

.customerComplexSearchCriteriaContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.customerTextFieldStyle{
  width: 200px;
  margin-right: 10px;
}

.customerFormSearchCriteria{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}